<template>
  <AppLayout>
    <main>
      <div
        class="page-header pb-10 page-header-dark bg-gradient-primary-to-secondary"
      >
        <div class="container-fluid">
          <div class="page-header-content">
            <h1 class="page-header-title">
              <div class="page-header-icon"><i data-feather="filter"></i></div>
              <span>New Rate</span>
            </h1>
            <div class="page-header-subtitle ml-2">Create New Rate</div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-n10">
        <div class="card mb-4">
          <div class="card-header">Set Default Rates</div>
          <div class="card-body">
            <div class="form">
              <form @submit.prevent="setupDefaultRate()">
                <div class="form-group">
                  <label for="">Broker</label
                  ><v-select
                    v-model="broker"
                    class="form-select"
                    label="name"
                    :options="paginated"
                    :filterable="false"
                    @search="onSearch"
                  >
                    <template #search="{ attributes, events }">
                      <span class="mt-1 mx-3 py-1" v-if="broker === null">
                        Search Broker
                      </span>

                      <input
                        class="vs__search"
                        :required="data.party !== '' ? false : true"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                </div>

                <button v-if="loading" class="btn btn-primary disabled">
                  <div
                    class="spinner-border spinner-border-sm mx-1 text-light"
                    rate="status"
                  ></div>
                  Loading...
                </button>
                <button v-else type="submit" class="btn btn-primary">
                  Set Rates
                </button>
              </form>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">New Custom Rate</div>
          <div class="card-body">
            <div class="form">
              <form @submit.prevent="handleSubmit()">
                <div class="form-group">
                  <label for="">Broker</label
                  ><v-select
                    v-model="broker"
                    class="form-select"
                    label="name"
                    :options="paginated"
                    :filterable="false"
                    @search="onSearch"
                  >
                    <template #search="{ attributes, events }">
                      <span class="mt-1 mx-3 py-1" v-if="broker === null">
                        Search Broker
                      </span>

                      <input
                        class="vs__search"
                        :required="data.party !== '' ? false : true"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                </div>

                <div class="form-group">
                  <label for="">Cover Scope</label
                  ><select
                    v-model="data.cover"
                    class="form-control"
                    id="typeofCover"
                    required
                  >
                    <option value="">Select Cover Scope</option>
                    <option
                      v-for="cover in coverScopes"
                      :key="cover.id"
                      :value="cover.id"
                    >
                      {{ cover.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="">Voyage Type</label
                  ><select
                    v-model="data.voyageType"
                    class="form-control"
                    id="typeofCover"
                    required
                  >
                    <option value="">Select Voyage Type</option>
                    <option
                      v-for="voyage in voyageTypes"
                      :key="voyage.id"
                      :value="voyage.name"
                    >
                      {{ voyage.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="">Type Of Cover</label
                  ><select
                    v-model="data.typeofCovers"
                    class="form-control"
                    id="typeofCover"
                    required
                  >
                    <option value="">Select Type Of Cover</option>
                    <option
                      v-for="cover in coverTypes"
                      :key="cover.id"
                      :value="cover.name"
                    >
                      {{ cover.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="name">Rate (%)</label
                  ><input
                    class="form-control"
                    id="name"
                    type="number"
                    step="0.001"
                    v-model="data.name"
                    placeholder="Enter Rate "
                  />
                </div>

                <button v-if="loading" class="btn btn-primary disabled">
                  <div
                    class="spinner-border spinner-border-sm mx-1 text-light"
                    rate="status"
                  ></div>
                  Loading...
                </button>
                <button v-else type="submit" class="btn btn-primary">
                  Create {{ data.rate }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useAdminStore } from "@/stores/admin";
import { useMarineStore } from "@/stores/marine";

export default {
  name: "New Rate",
  components: { AppLayout },
  data() {
    return {
      broker: null,
      search: "",
      offset: 0,
      limit: 100,
      data: {
        name: 0,
        typeofCovers: "",
        cover: "",
        voyageType: "",
        party: "",
        partyId: "",
      },

      metaData: {
        coverTypes: [
          "ICC(AIR)",
          "ICC'A'",
          "ICC'B'",
          "ICC'C'",
          "IFFC'A'",
          "IFFC'C'",
          "IBOC",
        ],

        coverScopes: ["MCI", "MOC"],
        voyageType: ["Port To Port", "Port To Warehouse"],

        coverTypeValues: {
          "ICC(AIR)": {
            MCI: {
              "Port To Port": 0.15,
              "Port To Warehouse": 0.25,
            },
            MOC: {
              "Port To Port": 0.15,
              "Port To Warehouse": 0.25,
            },
          },
          "ICC'A'": {
            MCI: {
              "Port To Port": 0.15,
              "Port To Warehouse": 0.25,
            },
            MOC: {
              "Port To Port": 0.15,
              "Port To Warehouse": 0.25,
            },
          },
          "ICC'B'": {
            MCI: {
              "Port To Port": 0.1,
              "Port To Warehouse": 0.2,
            },
            MOC: {
              "Port To Port": 0.1,
              "Port To Warehouse": 0.2,
            },
          },
          "ICC'C'": {
            MCI: {
              "Port To Port": 0.1,
              "Port To Warehouse": 0.2,
            },
            MOC: {
              "Port To Port": 0.1,
              "Port To Warehouse": 0.2,
            },
          },
          "IFFC'A'": {
            MCI: {
              "Port To Port": 0.15,
              "Port To Warehouse": 0.25,
            },
            MOC: {
              "Port To Port": 0.15,
              "Port To Warehouse": 0.25,
            },
          },
          "IFFC'C'": {
            MCI: {
              "Port To Port": 0.1,
              "Port To Warehouse": 0.2,
            },
            MOC: {
              "Port To Port": 0.1,
              "Port To Warehouse": 0.2,
            },
          },
          IBOC: {
            MCI: {
              "Port To Port": 0.1,
              "Port To Warehouse": 0.2,
            },
            MOC: {
              "Port To Port": 0.1,
              "Port To Warehouse": 0.2,
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState(useAdminStore, [
      "loading",
      "coverTypes",
      "users",
      "brokers",
      "voyageTypes",
    ]),
    ...mapState(useMarineStore, ["coverScopes"]),
    filtered() {
      return this.brokers.filter((broker) =>
        broker.name
          .toLocaleLowerCase()
          .includes(this.search.toLocaleLowerCase())
      );
    },
    paginated() {
      return this.filtered.slice(this.offset, this.limit + this.offset);
    },
  },

  methods: {
    ...mapActions(useAdminStore, [
      "createRate",
      "success",
      "bulkCreateRate",
      "getAllCoverTypes",
      "getAllUsers",
      "getAllVoyageTypes",
    ]),
    ...mapActions(useMarineStore, ["getCoverScopes"]),
    async handleSubmit() {
      const res = await this.createRate(this.data);
      if (res == "success") {
        this.$router.push("/rates");
      }
    },

    onSearch(query) {
      this.search = query;
      this.offset = 0;
    },

    addBroker(broker) {
      if (this.broker !== null) {
        this.data.party = broker.name;
        this.data.partyId = broker.id.toString();
      }
    },

    async setupDefaultRate() {
      for (let coverType of this.metaData.coverTypes) {
        for (let mciType of this.metaData.coverScopes) {
          for (let voyageType of this.metaData.voyageType) {
            let value;
            if (typeof this.metaData.coverTypeValues[coverType] === "object") {
              value =
                this.metaData.coverTypeValues[coverType][mciType][voyageType];
            } else {
              value = this.metaData.coverTypeValues[coverType];
            }
            const requestBody = {
              typeOfCovers: coverType,
              cover: mciType,
              voyageType: voyageType,
              name: value,
              partyId: this.data.partyId,
              party: this.data.party,
            };
            await this.bulkCreateRate(requestBody);
          }
        }
      }

      await this.success("Rates Created SUccessfully");
      this.$router.push("/rates");
    },
  },

  watch: {
    broker: function () {
      if (this.broker !== null) {
        this.addBroker(this.broker);
      }
    },
  },

  created() {
    this.getAllCoverTypes();
    this.getAllUsers();
    this.getCoverScopes();
    this.getAllVoyageTypes();
  },
};
</script>

<style scoped>
.pagination {
  z-index: 100;
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}
</style>
